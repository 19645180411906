<template>
  <div class="mynews-main">
    <Breadcrumb :breadcrumbList="list"></Breadcrumb>

    <div class="form">
      <div class="form-main">
        <div class="screen">
          <div class="state">
            <div class="name">消息状态:</div>
            <el-select
              v-model="reading"
              placeholder="请选择"
              size="small"
              class="app-state"
              @change="getMessages"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <div class="name name-type">消息类别:</div>
            <el-select
              v-model="searchCateId"
              placeholder="搜索类型"
              size="small"
              class="app-name"
              @change="getMessages"
            >
              <el-option
                v-for="item in searchType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="box">
            <input placeholder="请输入消息简介" v-model="key"   maxlength="1000" />
            <el-button type="primary" @click="getMessages"
              >搜索 <i class="el-icon-search el-icon--right"
            /></el-button>
          </div>
        </div>

        <div class="table">
          <el-table
            height="540"
            :data="mynewsList"
            header-row-class-name="header-row"
          >
            <el-table-column type="index" label="序号" width="48">
            </el-table-column>
            <el-table-column prop="dateline" label="接收日期">
            </el-table-column>
            <el-table-column prop="cateId" label="类别" width="100">
              <template slot-scope="scope">
                {{ scope.row.cateId | filterCateId(searchType) }}
              </template>
            </el-table-column>
            <el-table-column prop="relationId" label="项目编号" width="200">
            </el-table-column>
            <el-table-column prop="title" label="消息简介"> </el-table-column>
            <el-table-column prop="reading" label="消息状态" width="200">
              <template slot-scope="scope">
                {{ scope.row.reading ? "已读" : "未读" }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <span class="detail" @click="detailShow(scope.row)"
                  >查看详情</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="pagination-box">
          <span class="total-pages">共{{ PageCount }}页</span>
          <el-pagination
            @size-change="getMessages"
            @current-change="getMessages"
            :current-page.sync="page"
            :page-size="size"
            background
            layout="prev, pager, next,jumper"
            :total="total"
          >
          </el-pagination>
        </div>

        <div class="footer-fa">
          <Footer></Footer>
        </div>
      </div>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="detailVisible"
      width="480px"
      :before-close="detailClose"
      :close-on-click-modal="false"
    >
      <img
        class="close"
        @click="detailClose"
        src="@/assets/images/close.png"
        alt="图片"
      />
      <div class="title">查看详情</div>
      <div class="content">
        {{ detailContent }}
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import Footer from "@/components/Footer/homePageFooter.vue";
import { getMessages, setReadMessage, getMessagesUnreadCount } from "@/api/app";
export default {
  components: { Breadcrumb, Footer },
  filters: {
    filterCateId(val, type) {
      let name = null;
      type.forEach((element) => {
        if (element.value === val) {
          name = element.label;
        }
      });
      return name;
    },
  },
  data() {
    return {
      list: [
        {
          name: "消息管理",
          path: null,
        },
        {
          name: "我的消息",
          path: null,
        },
      ],
      reading: null,
      options: [
        {
          value: null,
          label: "全部",
        },
        {
          value: true,
          label: "已读",
        },
        {
          value: false,
          label: "未读",
        },
      ],
      searchCateId: null,
      key: "",
      searchType: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 1,
          label: "项目提醒",
        },
        {
          value: 2,
          label: "QC提醒",
        },
        {
          value: 3,
          label: "订单提醒",
        },
      ],
      page: 1,
      size: 10,
      total: null,
      PageCount: null,
      mynewsList: [],
      detailVisible: false,
      detailContent: null,
    };
  },
  created() {
    this.getMessages();
  },
  methods: {
    getMessages() {
      const params = {
        page: this.page,
        size: this.size,
        reading: this.reading,
        categoryId: this.searchCateId,
        key: this.key,
      };
      getMessages(params).then((res) => {
        console.log("我i的消息", res.data);

        this.total = res.data.dataCount;
        this.PageCount = res.data.pageCount;
        this.mynewsList = res.data.data;
      });
    },
    detailClose() {
      this.detailVisible = false;
    },
    detailShow(data) {
      this.detailVisible = true;
      this.detailContent = data.content;
      if (!data.reading) {
        const arr = [];
        arr.push(data.id);
        this.setReadMessage(arr);
      }
    },
    //已读消息
    setReadMessage(arr) {
      setReadMessage(arr).then((res) => {
        if (res.status === 200) {
          this.getMessages();
          this.getMessagesUnreadCount();
        }
      });
    },
    //获取全部未读消息数量
    getMessagesUnreadCount() {
      getMessagesUnreadCount().then((res) => {
        if (res.status === 200) {
          this.$store.commit("application/setNoMessageNumber", res.data);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mynews-main {
  padding: 20px 0 0 20px;
  min-height: calc(100% - 20px);
  .form {
    min-height: calc(100vh - 153px);
    background-color: #fff;
    margin-top: 20px;
    .form-main {
      width: 100%;
      min-height: calc(100% - 68px);
      background-color: #fff;
      margin-top: 20px;
      .screen {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 21px 24px 0 24px;
        .app-name {
          width: 100px;
        }
        .state {
          display: flex;
          align-items: center;
          .name {
            font-size: 14px;
            margin-right: 7px;
          }
          .name-type {
            margin-left: 40px;
          }
          .app-state {
            width: 150px;
          }
        }
        .box {
          display: flex;
          align-items: center;
          input {
            width: 184px;
            height: 32px;
            border: 1px solid #dddddd;
            border-radius: 4px;
            padding-left: 10px;
            margin-right: 10px;
            margin-left: 10px;
          }
          .el-button {
            background-color: #4578ff;
            color: #fff;
            height: 32px;
            width: 84px;
            padding: 0;
          }
        }
      }
    }
    .footer-fa {
      padding-right: 20px;
      background-color: #fff;
      margin-top: 50px;
      /deep/ .home-page-footer {
        margin-bottom: 0 !important;
        padding-bottom: 25px;
      }
    }
  }
}
.table {
  padding: 0 24px 0 24px;
  width: calc(100% - 48px);
  .el-table {
    width: 100%;
    margin-top: 27px;
    /deep/ .header-row {
      background-color: #f5f7fd;
      .el-table__cell {
        background-color: #f5f7fd;
        color: #06052b;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
      }
    }
    /deep/ .el-table__cell {
      text-align: center;
    }
    .no-wrap {
      white-space: nowrap;
      word-break: keep-all;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .row-state {
      display: flex;
      align-items: center;
      span {
        width: 7px;
        height: 7px;
        background: #698af7;
        border-radius: 7px;
        margin-right: 8px;
        margin-left: 10px;
      }
    }
    .operate {
      display: flex;
      justify-content: center;
      font-size: 14px;
      color: #4578ff;
      .left {
        margin-right: 0px;
        width: 100px;
      }
      .right {
        width: 100px;
      }
      .complete {
        color: #9091a1;
      }
      .progress {
        width: 100px;
      }
      div {
        cursor: pointer;
      }
    }
  }
}
.pagination-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  .total-pages {
    color: #323233;
    font-size: 14px;
    margin-right: 18px;
  }
  .el-pagination {
    margin-right: 20px;
  }
}
.detail {
  color: #4578ff;
  cursor: pointer;
}
.el-dialog__wrapper {
  /deep/ .el-dialog__header {
    display: none;
  }
  /deep/ .el-dialog__body {
    padding: 20px;
  }
  .close {
    float: right;
    width: 14px;
    cursor: pointer;
  }
  .title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #06052b;
  }
  .content {
    margin-top: 20px;
  }
}
</style>